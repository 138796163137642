import React, { useCallback, useRef } from "react";
import { IMenuRuleComponent, IRuleComponent, IState, Stereotype } from "store/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { Popover, Tooltip } from "@mui/material";
import { RuleMenu } from "./RuleMenu";
import { useDispatch, useSelector } from "react-redux";
import { flipExpansion } from "actions/editorState";
import { RuleShortcuts } from "./RuleShortcuts";
import { featureToggles } from "config/featureToggles";

interface IProps {
  rule: IMenuRuleComponent;
  parentTemplate?: IRuleComponent;
  iconStyle?: any;
  ruleBeingUsed?: (ruleId: string, showDialog?: boolean) => boolean;
}

interface IConditionalTooltipProps {
  show?: boolean;
  title: any;
  children: any;
}
const ConditionalTooltip = (props: IConditionalTooltipProps) =>
  props.show ? (
    <Tooltip title={props.title} placement="top" arrow>
      {props.children}
    </Tooltip>
  ) : (
    <>{props.children}</>
  );

export const ActionMenu = (props: IProps) => {
  const { rule, parentTemplate, iconStyle, ruleBeingUsed } = props;
  const anchorEl = useRef(null);
  const menuOpenForRuleId = useSelector((s: IState) => s.editorState.menuOpenForRuleId);
  const moveRuleState = useSelector((s: IState) => s.moveRuleState);
  const newlyCreatedRules = useSelector((s: IState) => s.capsObject?.newlyCreatedRules);
  const dispatch = useDispatch();

  const flipMenuAction = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(flipExpansion(rule.id));
    },
    [dispatch, rule.id],
  );
  const btnId = `${rule.id}-action-menu-btn`;
  const popoverId = `${rule.id}-action-menu`;

  const showTooltip = rule.stereotype === Stereotype.RuleGroup;

  // Do not allow expansion if something else is expanded
  const isMenuActive = !!(menuOpenForRuleId && menuOpenForRuleId === rule.id);
  // const isAnotherMenuActive = menuOpenForRuleId && !isMenuActive;
  if (moveRuleState || newlyCreatedRules) {
    return null;
  }

  return (
    <>
      <ConditionalTooltip
        show={showTooltip}
        title={featureToggles.shortcutMenuItems ? "More options ..." : "Add rule ..."}
      >
        <IconButton
          size="small"
          ref={anchorEl}
          aria-label="plan-action-menu"
          id={btnId}
          onClick={flipMenuAction}
          className={featureToggles.shortcutMenuItems ? "reveal-on-hover" : ""}
        >
          <MoreVertIcon style={iconStyle || {}} />
        </IconButton>
      </ConditionalTooltip>
      <Popover
        id={popoverId}
        open={isMenuActive}
        onClose={flipMenuAction}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <RuleMenu rule={rule} parentTemplate={parentTemplate} ruleBeingUsed={ruleBeingUsed} />
      </Popover>
      <RuleShortcuts rule={rule} parentTemplate={parentTemplate} ruleBeingUsed={ruleBeingUsed} />
    </>
  );
};
