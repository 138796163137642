import React, { useCallback, useState } from "react";
import { Theme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Paper, useTheme } from "@mui/material";
import { IMenuRuleComponent, IRuleComponent, RuleComponentType, Stereotype } from "store/types";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import {
  addSharedDynamicSubjectSet,
  addSharedStaticSubjectSet,
  openAddEditor,
  openAddGroupEditor,
  openEditor,
  closeEditor,
  flipExpandRuleSection,
  flipPreviewRule,
} from "actions/editorState";
import { deleteOneRule } from "../../../actions/editorState";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { getLabel } from "lib/parameters";
import { startMove } from "actions/moveRuleState";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { DeleteConfirmationModal } from "components/delete-confirmation/DeleteConfirmationModal";
import { featureToggles } from "config/featureToggles";

const useStyles = (theme: Theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

interface IProps {
  rule: IMenuRuleComponent;
  parentTemplate?: IRuleComponent;
  ruleBeingUsed?: (ruleId: string, showDialog?: boolean) => boolean;
}

const defaultOnClick = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

export const RuleMenu = (props: IProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { rule, ruleBeingUsed, parentTemplate } = props;
  const dispatch = useDispatch();
  //const targetType = useSelector((state: IState) => state.targetType);
  const expandedRuleSections = useSelector((state: IState) => state.editorState.expandedSections);
  const previewedRuleId = useSelector((state: IState) => state.editorState.previewedRuleId);
  const isPreviewed = previewedRuleId && (previewedRuleId === rule.id || previewedRuleId === parentTemplate?.id);
  // Show different logic for groups and what not
  const isGroup = rule.stereotype === Stereotype.RuleGroup;
  const isRuleSection = rule.stereotype === Stereotype.RuleSection;
  const isRoot = rule.stereotype === Stereotype.Rules;
  const isSubjectSet = rule.stereotype === Stereotype.RuleComponent && rule.type === RuleComponentType.SubjectSet;
  const isExpandedRuleSection = isRuleSection && expandedRuleSections && expandedRuleSections[rule.id];
  //const isSubjectLegacy = RuleTargetType.Subject === targetType && capsObject && capsObject.isLegacy;
  const newlyCreatedRules = useSelector((s: IState) => s.capsObject?.newlyCreatedRules);

  // Handle the confirmation modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const closeDeleteModalOpen = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(closeEditor());
      setDeleteModalOpen(false);
    },
    [dispatch],
  );
  const flipDeleteModalOpen = useCallback(
    (e: any) => {
      defaultOnClick(e);
      // dispatch(closeEditor());
      if (!deleteModalOpen && ruleBeingUsed !== undefined && !ruleBeingUsed(rule.id, true)) {
        setDeleteModalOpen(true);
      } else if (!deleteModalOpen && ruleBeingUsed === undefined) {
        setDeleteModalOpen(true);
      }
    },
    [deleteModalOpen, rule.id, ruleBeingUsed],
  );

  const onEditClick = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(closeEditor());
      if (ruleBeingUsed !== undefined) {
        ruleBeingUsed(rule.id);
      }
      dispatch(openEditor(rule.id));
    },
    [dispatch, rule.id, ruleBeingUsed],
  );

  const onDeleteClick = useCallback(
    (e: any) => {
      if (e) {
        defaultOnClick(e);
      }
      dispatch(closeEditor());
      if (ruleBeingUsed !== undefined && !ruleBeingUsed(rule.id, true)) {
        dispatch(deleteOneRule(rule.id));
      } else if (ruleBeingUsed === undefined) {
        dispatch(deleteOneRule(rule.id));
      }
    },
    [dispatch, rule.id, ruleBeingUsed],
  );

  const onAddRuleClick = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(openAddEditor(rule.id));
    },
    [dispatch, rule.id],
  );

  const onAddGroupRuleClick = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(openAddGroupEditor(rule));
    },
    [dispatch, rule],
  );

  const onAddStaticSet = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(addSharedStaticSubjectSet());
    },
    [dispatch],
  );

  const onAddDynamicSet = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(addSharedDynamicSubjectSet());
    },
    [dispatch],
  );

  const onRuleSectionFlipExpansion = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(flipExpandRuleSection(rule.id));
    },
    [dispatch, rule.id],
  );

  const onPreview = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(flipPreviewRule(rule.id));
    },
    [dispatch, rule.id],
  );

  const onPreviewCancel = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(flipPreviewRule(null));
    },
    [dispatch],
  );

  const ruleId = rule.id;
  const ruleStereotype = rule.stereotype;
  const ruleLabel = getLabel(rule) || "";
  const onCut = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(
        startMove({
          ruleId,
          label: ruleLabel,
          stereotype: ruleStereotype,
        }),
      );
    },
    [dispatch, ruleId, ruleLabel, ruleStereotype],
  );

  const onCopy = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(
        startMove({
          ruleId,
          label: ruleLabel,
          stereotype: ruleStereotype,
          copy: true,
        }),
      );
    },
    [dispatch, ruleId, ruleLabel, ruleStereotype],
  );

  if (newlyCreatedRules) {
    return null;
  }

  return (
    <Paper elevation={3}>
      {deleteModalOpen && (
        <DeleteConfirmationModal open={deleteModalOpen} onCancel={closeDeleteModalOpen} onDelete={onDeleteClick} />
      )}
      <List component="ul" aria-labelledby="nested-list-subheader" sx={styles.root}>
        {!featureToggles.shortcutMenuItems && !isRuleSection && !isRoot && (
          <ListItem button onClick={onEditClick}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItem>
        )}

        {!featureToggles.shortcutMenuItems && isGroup && !isRuleSection && (
          <ListItem button onClick={onAddRuleClick}>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Add rule" />
          </ListItem>
        )}

        {!featureToggles.shortcutMenuItems && (isGroup || isRuleSection) && (
          <ListItem button onClick={onAddGroupRuleClick}>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Add a rule group" />
          </ListItem>
        )}

        {!isRuleSection && !isRoot && (
          <ListItem button onClick={featureToggles.confirmOnDelete ? flipDeleteModalOpen : onDeleteClick}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ListItem>
        )}

        {isRoot && (
          <ListItem button onClick={onAddStaticSet}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Add static subject list" />
          </ListItem>
        )}

        {isRoot && (
          <ListItem button onClick={onAddDynamicSet}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Add dynamic subject list" />
          </ListItem>
        )}

        {!isRuleSection && !isRoot && (
          <ListItem button onClick={onCut}>
            <ListItemIcon>
              <i className="material-icons">content_cut</i>
            </ListItemIcon>
            <ListItemText primary="Cut ..." />
          </ListItem>
        )}

        {!isRuleSection && !isRoot && (
          <ListItem button onClick={onCopy}>
            <ListItemIcon>
              <i className="material-icons">content_copy</i>
            </ListItemIcon>
            <ListItemText primary="Copy ..." />
          </ListItem>
        )}

        {isRuleSection && (
          <ListItem button onClick={onRuleSectionFlipExpansion}>
            <ListItemIcon>{isExpandedRuleSection ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
            <ListItemText primary={isExpandedRuleSection ? "Collapse All" : "Expand All"} />
          </ListItem>
        )}

        {featureToggles.previewNavigation && !isRoot && !isSubjectSet && !isPreviewed && (
          <ListItem button onClick={onPreview}>
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText primary={"Preview output"} />
          </ListItem>
        )}

        {featureToggles.previewNavigation && !isRoot && !isSubjectSet && isPreviewed && (
          <ListItem button onClick={onPreviewCancel}>
            <ListItemIcon>
              <VisibilityOffIcon />
            </ListItemIcon>
            <ListItemText primary={"Cancel preview selection"} />
          </ListItem>
        )}
      </List>
    </Paper>
  );
};
