import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import {
  GET_CAPS_OBJ,
  UPDATE_CAPS_OBJ,
  POST_RULEGROUP_CAPS_OBJ,
  ADD_CAPS_OBJ,
  MOVE_RULE,
  UPDATE_CAPS_OBJ_RAW,
  ADD_SHARED_SUBJECT_SET,
  CANCEL_MIGRATED_RULES,
  RESAVE_CAPS_OBJ,
} from "actions/capsObject";

import { DELETE_RULE_EDITOR } from "actions/editorState";
import { duplicateIds } from "lib/rules";
import omit from "lodash/omit";
import { IRuleComponent } from "store/types";
import { getLabel } from "lib/parameters";

export const logRules = (rules: IRuleComponent, level = 0): void => {
  console.log(`${" ".repeat(level * 2)}${getLabel(rules) ?? ""},${rules.stereotype}, ${rules.type}, ${rules.id}`);
  for (const c of rules.children) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logRules(c, level + 1);
  }
  const shared = rules.sharedComponents || [];
  for (const sc of shared) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    console.log(`${" ".repeat((level + 1) * 2)}- Shared: ${sc.stereotype}, ${sc.type}, ${sc.id}`);
  }
};

const validatePayload = (rules: any) => {
  // console.log("== == == == == == == == == == ==");
  // logRules(rules);
  // console.log("== == == == == == == == == == ==");
  const duplicates = duplicateIds(rules);
  if (duplicates.length > 0) {
    alert(`Warning! Detected duplicate rule ids:\n- ${duplicates.join("\n- ")}

Drag-and-drop and other operations may not work properly.`);
  }
};

const deleteMigratedRules = (rules: any) => {
  if (!rules) {
    return {};
  }
  return {
    ...rules,
    children: rules.children.map((c: any) => ({ ...c, children: [] })),
  };
};

// Reduce the size of what we keep in Redux ...
const trimCapsObject = (obj: any) =>
  obj &&
  omit(
    obj,
    "overview",
    "courseRules",
    "structure",
    "ruleText",
    "prerequisiteDescription",
    "corequisiteDescription",
    "disallowedSubjectsDescription",
  );

const capsObject = (state = initialState.capsObject, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case CANCEL_MIGRATED_RULES:
      const { newlyCreatedRules: _unused1, ...relevanStateAfterCancel } = state || ({} as any);
      return trimCapsObject({
        ...relevanStateAfterCancel,
        rules: deleteMigratedRules(relevanStateAfterCancel?.rules),
      });

    case fulfilled(GET_CAPS_OBJ):
    case fulfilled(RESAVE_CAPS_OBJ):
      const obj = payload.courses || payload.subject || payload.component || {};
      validatePayload(obj.rules);
      const result = {
        ...state,
        ...obj,
        newlyCreatedRules: !!obj.newlyCreatedRules,
      };
      if (!result.newlyCreatedRules) {
        delete result.newlyCreatedRules;
      }
      return trimCapsObject(result);
    case fulfilled(UPDATE_CAPS_OBJ):
    case fulfilled(ADD_SHARED_SUBJECT_SET):
    case fulfilled(DELETE_RULE_EDITOR):
    case fulfilled(POST_RULEGROUP_CAPS_OBJ):
    case fulfilled(ADD_CAPS_OBJ):
    case fulfilled(MOVE_RULE):
    case fulfilled(UPDATE_CAPS_OBJ_RAW):
      validatePayload(action.payload.rules);
      const { newlyCreatedRules: _unused2, ...relevanStateAfterUpdate } = state || {};
      return trimCapsObject({
        ...relevanStateAfterUpdate,
        ...action.payload,
        componentReferences: (state as any).componentReferences ?? action.payload.componentReferences ?? [],
      });
    default:
      return state;
  }
};

export default capsObject;
