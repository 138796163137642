import { failed, fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "actions/snackBar";
import { ActionType } from "redux-promise-middleware";
import {
  ADD_CAPS_OBJ,
  GET_CAPS_OBJ,
  MOVE_RULE,
  UPDATE_CAPS_OBJ,
  ADD_SHARED_SUBJECT_SET,
  RESAVE_CAPS_OBJ,
} from "actions/capsObject";
import { GET_SUBJECT_BY_CODE, GET_SUBJECT_DETAILS } from "actions/subjectDetails";
import { GET_TEMPLATES } from "actions/templates";
import { GET_MMS_DETAILS, GET_MMS_BY_CODE } from "actions/mmsDetails";
import { GET_COURSES_AND_MMSES, SEARCH_COURSE_BY_NAME } from "actions/courses";

const err = (message: string, autoHideDuration = 30000) => ({
  type: "error",
  message,
  autoHideDuration,
});

const success = (message: string, autoHideDuration = 3000) => ({
  type: "success",
  message,
  autoHideDuration,
});

const snackBarState = (state = initialState.snackBarState || null, action: any) => {
  switch (action.type) {
    case CLOSE_SNACKBAR:
      return null;
    case OPEN_SNACKBAR:
      return action.payload;
    case failed(GET_CAPS_OBJ):
      return err("Could not object from CAPS");
    case failed(UPDATE_CAPS_OBJ):
    case failed(ADD_CAPS_OBJ):
    case failed(RESAVE_CAPS_OBJ):
      return err("Could not save object in CAPS");
    case failed(GET_SUBJECT_DETAILS):
    case failed(GET_SUBJECT_BY_CODE):
      return err("Could not retrieve subject details from CAPS");
    case failed(GET_MMS_DETAILS):
    case failed(GET_MMS_BY_CODE):
      return err("Could not retrieve MMS details from CAPS");
    case failed(GET_COURSES_AND_MMSES):
      return err("Could not retrieve MMS and course details from CAPS");
    case failed(SEARCH_COURSE_BY_NAME):
      return err("Could not rereive course details from CAPS");
    case failed(GET_TEMPLATES):
      return err("Could not retrieve templates");
    case failed(MOVE_RULE):
      return err("Could not move rule");
    case failed(ADD_SHARED_SUBJECT_SET):
      return err("Could not create shared subject set");
    case fulfilled(RESAVE_CAPS_OBJ):
      return success("Resaved CAPS object");
    default:
      // Handle unknown errors
      if (action.type && (action.type as string).endsWith(ActionType.Rejected)) {
        return err("An unknown error has occurred");
      } else {
        return state;
      }
  }
};

export default snackBarState;
