interface IFeatureToggles {
  search?: boolean;
  nonAllowedDefaults?: boolean;
  admission?: boolean;
  confirmOnDelete?: boolean;
  previewNavigation?: boolean;
  previewOpenByDefault?: boolean;
  bracketConnectors?: boolean;
  shortcutMenuItems?: boolean;
  previewRuleBeforeSave?: boolean;
  progression?: boolean;
  showDependentObjects?: boolean;
  linkText?: boolean;
  resave?: boolean;
  versionLess?: boolean;
  subjectExclusion?: boolean;
  exclusiveGroupTag?: boolean;
  operator?: boolean;
  hideOperatorXOR?: boolean;
  hideOperatorNOT?: boolean;
  availabilityTags?: boolean;
  countAllSubjectsForComponentTag?: boolean;
}

const allFeatureToggles: Record<string, IFeatureToggles> = {
  localDev: {
    search: true,
    nonAllowedDefaults: true,
    admission: true,
    confirmOnDelete: true,
    previewNavigation: true,
    previewOpenByDefault: true,
    bracketConnectors: true,
    shortcutMenuItems: true,
    previewRuleBeforeSave: true,
    progression: true,
    showDependentObjects: true,
    linkText: true,
    resave: true,
    versionLess: true,
    subjectExclusion: true,
    exclusiveGroupTag: true,
    hideOperatorXOR: true,
    hideOperatorNOT: true,
    availabilityTags: true,
    countAllSubjectsForComponentTag: true,
  },
  development: {
    search: true,
    nonAllowedDefaults: true,
    admission: true,
    confirmOnDelete: true,
    previewNavigation: true,
    previewOpenByDefault: true,
    bracketConnectors: true,
    shortcutMenuItems: true,
    previewRuleBeforeSave: true,
    progression: true,
    showDependentObjects: true,
    linkText: true,
    resave: true,
    versionLess: true,
    subjectExclusion: true,
    exclusiveGroupTag: true,
    hideOperatorXOR: true,
    hideOperatorNOT: true,
    availabilityTags: true,
    countAllSubjectsForComponentTag: true,
  },
  test: {
    search: true,
    nonAllowedDefaults: true,
    confirmOnDelete: true,
    admission: true,
    previewNavigation: true,
    previewOpenByDefault: true,
    bracketConnectors: true,
    shortcutMenuItems: true,
    previewRuleBeforeSave: true,
    progression: true,
    showDependentObjects: true,
    linkText: true,
    resave: true,
    versionLess: true,
    subjectExclusion: true,
    exclusiveGroupTag: true,
    hideOperatorXOR: true,
    hideOperatorNOT: true,
    availabilityTags: true,
    countAllSubjectsForComponentTag: true,
  },
  staging: {
    search: true,
    nonAllowedDefaults: true,
    admission: true,
    confirmOnDelete: true,
    previewNavigation: true,
    previewOpenByDefault: true,
    bracketConnectors: true,
    shortcutMenuItems: true,
    previewRuleBeforeSave: true,
    progression: true,
    showDependentObjects: true,
    linkText: true,
    resave: true,
    subjectExclusion: true,
    exclusiveGroupTag: true,
    hideOperatorXOR: true,
    hideOperatorNOT: true,
    availabilityTags: true,
    countAllSubjectsForComponentTag: true,
  },
  production: {
    search: true,
    nonAllowedDefaults: true,
    confirmOnDelete: true,
    admission: true,
    previewNavigation: true,
    previewOpenByDefault: true,
    bracketConnectors: true,
    shortcutMenuItems: true,
    previewRuleBeforeSave: true,
    progression: true,
    showDependentObjects: true,
    linkText: true,
    resave: true,
    subjectExclusion: true,
    exclusiveGroupTag: true,
    hideOperatorXOR: true,
    hideOperatorNOT: true,
    availabilityTags: true,
    countAllSubjectsForComponentTag: true,
  },
};
allFeatureToggles["default"] = allFeatureToggles.development;

const { DEPLOYMENT_ENVIRONMENT } = (window as any).env;
const { NODE_ENV } = process.env;
const envKey = NODE_ENV === "development" ? "localDev" : DEPLOYMENT_ENVIRONMENT!;

export const featureToggles = allFeatureToggles[envKey];
